// 
// _header.scss
// 

#page-topbar {
    margin-left: $sidebar-width;
    transition: all 0.2s ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1002;
    background-color: $header-bg;
}

.navbar-header {
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: $header-height;
    padding: 0 $grid-gutter-width 0 0;
    background-color: #fff;

    .dropdown .show {
        &.header-item {
            background-color: $gray-100;
        }
    }
}

body[data-layout-mode="dark"] {
    .navbar-header {
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        height: $header-height;
        padding: 0 $grid-gutter-width 0 0;
        background-color: #0A172C;

        .dropdown .show {
            &.header-item {
                background-color: $gray-100;
            }
        }
    }
}

.navbar-brand-box-container {
    border-right: 1px solid $sidebar-border-color;
}

.navbar-brand-box {
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    width: $navbar-brand-box-width;
    background: $sidebar-bg;
    box-shadow: 0px 2px 2px $sidebar-bg;
}

.logo-container {
    max-height: $header-height;
}

.logo-big,
.logo-sm {
    display: flex;
    margin-top: 5px;
    margin-bottom: 1rem;
    line-height: 69px;
    color: inherit !important;
    height: 60%;
}

.logo-txt {
    font-weight: 700;
    font-size: 18px;
    vertical-align: middle;
    margin-left: 5px;
}

.logo-light {
    display: none;
}


body:not([data-sidebar-size="sm"]) {
    #vertical-menu-btn {
        margin-right: 20px;

        @media (max-width: 991.98px) {
            margin-left: 0;
        }
    }
}

/* Search */

.app-search {
    padding: calc(#{$header-height - 40px} / 2) 0;
    position: relative;

    .form-control {
        border: none;
        height: 40px;
        padding-left: 17px;
        width: 300px;
        background-color: $topbar-search-bg;
        box-shadow: none;
    }

    .btn {
        position: absolute;
        right: 3px;
        top: 3px;
        height: 34px;
        padding: 0 10px;
    }

    input {
        background: transparent !important;
    }
}

.layout-mode-light {
    display: none;
}

body[data-layout-mode="dark"] {
    .layout-mode-dark {
        display: none;
    }

    .layout-mode-light {
        display: inline-block;
    }
}

// Mega menu

.megamenu-list {
    li {
        position: relative;
        padding: 5px 0px;

        a {
            color: $dropdown-color;
        }
    }
}

@media (max-width: 992px) {
    .navbar-brand-box {
        width: auto;
    }

    .logo {

        span.logo-lg {
            // display: none;
        }

        span.logo-sm {
            display: inline-block;
        }
    }
}

.page-content {
    padding: calc(#{$header-height}) 0 0 0;
}

.header-item {
    height: $header-height;
    box-shadow: none !important;
    color: $header-item-color;
    border: 0;
    border-radius: 0px;

    &:hover {
        color: $header-item-color;
    }
}

.header-profile-user {
    height: 36px;
    width: 36px;
    background-color: $gray-600;
    padding: 3px;
}

.noti-icon {
    i {
        font-size: 22px;
        color: $header-item-color;
    }

    .badge {
        position: absolute;
        top: 25px;
        right: 4px;
    }
}

.notification-item {
    .d-flex {
        padding: 0.75rem 1rem;

        &:hover {
            background-color: $gray-100;
        }
    }
}

// Dropdown with Icons
.dropdown-icon-item {
    display: block;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    display: block;
    color: $gray-600;

    img {
        height: 24px;
    }

    span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:hover {
        background-color: $gray-100;
    }
}

// Full Screen
.fullscreen-enable {
    [data-toggle="fullscreen"] {
        .bx-fullscreen::before {
            content: "\ea3f";
        }
    }
}

body[data-topbar="dark"] {
    #page-topbar {
        background-color: $header-dark-bg;
        border-color: $gray-dark-300;
    }

    .navbar-brand-box {
        background-color: $header-dark-bg;
        border-color: $header-dark-bg;
        box-shadow: 0px 0px 2px $header-dark-bg;

        .logo {
            color: $white !important;
        }
    }

    .navbar-header {
        .dropdown .show {
            &.header-item {
                background-color: rgba($white, 0.05);
            }
        }

        .waves-effect .waves-ripple {
            background: rgba($white, 0.4);
        }
    }

    .header-item {
        color: $header-dark-item-color;

        &:hover {
            color: $header-dark-item-color;
        }

        &.border-start,
        &.border-end {
            border-color: rgba($white, 0.1) !important;
        }

        &.bg-soft-light {
            background-color: rgba($white, 0.04) !important;
        }
    }

    .header-profile-user {
        background-color: rgba($white, 0.25);
    }

    .noti-icon {
        i {
            color: $header-dark-item-color;
        }
    }

    .logo-dark {
        display: none;
    }

    .logo-light {
        display: block;
    }

    .app-search {

        .form-control {
            background-color: rgba($topbar-search-bg, 0.07);
            color: $white;
        }

        span,
        input.form-control::-webkit-input-placeholder {
            color: rgba($white, 0.5);
        }
    }
}

@media (max-width: 600px) {
    .navbar-header {
        .dropdown {
            position: static;

            .dropdown-menu {
                left: 10px !important;
                right: 10px !important;
            }
        }
    }
}

body[data-layout="horizontal"] {
    .navbar-brand-box {
        width: auto;
        border: 0;
        background-color: transparent;
        box-shadow: none;

        @media (min-width: 992px) {
            padding-left: 0;
            margin-right: 10px;
        }
    }

    .page-content {
        margin-top: $header-height;
        padding: calc(55px + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2) $footer-height calc(#{$grid-gutter-width} / 2);
    }

    .navbar-header {
        @media (min-width: 992px) {
            padding-left: $grid-gutter-width;
            padding-right: $grid-gutter-width;
        }
    }

    &[data-sidebar=dark] {
        .navbar-brand-box {
            background-color: transparent;
            box-shadow: none;
        }
    }
}

@media (max-width: 992px) {
    body[data-layout="horizontal"] {
        .page-content {
            margin-top: 15px;
        }
    }
}

body[data-layout-mode="dark"] {
    .page-content {
        background-color: #111A26;
    }

    .header-item {

        &.border-start,
        &.border-end {
            border-color: rgba($white, 0.1) !important;
        }

        &.bg-soft-light {
            background-color: rgba($white, 0.04) !important;
        }
    }

    #page-topbar {
        background-color: $main-dark-bg;
        border-color: $gray-dark-300;
    }

    .app-search {

        // Form Control
        .form-control {
            color: $gray-dark-500;
            background-color: lighten($gray-dark-200, 2%);
            border: 1px solid $gray-dark-300;
        }
    }

    .notification-item {
        .d-flex:hover {
            background-color: lighten($gray-dark-200, 4%);
        }
    }

    // Dropdown with Icons
    .dropdown-icon-item {
        color: $gray-dark-500;

        &:hover {
            background-color: $gray-dark-300;
        }
    }

    &[data-sidebar="light"] {
        @media (min-width: 992px) {
            &[data-topbar="light"] {
                .navbar-brand-box {
                    .logo {
                        color: $dark !important;
                    }
                }

                #vertical-menu-btn {
                    color: $dark;
                }
            }
        }
    }
}

body[data-layout-mode="dark"][data-topbar="light"] {

    .navbar-brand-box {
        background: $header-bg;
        border-right: none;
        box-shadow: none;

        .logo {
            color: $dark !important;
        }
    }


    .logo-light {
        display: none;
    }

    .logo-dark {
        display: block;
    }

    #vertical-menu-btn {
        color: $dark !important;
    }

    .header-item {
        color: $header-item-color;

        &:hover {
            color: $header-item-color;
        }

        &.border-start,
        &.border-end {
            border-color: rgba($dark, 0.1) !important;
        }

        &.bg-soft-light {
            background-color: rgba($dark, 0.04) !important;
        }
    }


    #page-topbar {
        background-color: $header-bg;
        border-color: $gray-300;
    }

    // Dropdown with Icons
    .dropdown-icon-item {
        color: $gray-500;

        &:hover {
            background-color: $gray-300;
        }
    }

    .app-search {

        // Form Control
        .form-control {
            color: $gray-500;
            background-color: lighten($gray-200, 2%);
            border: 1px solid $gray-300;
        }
    }

    .header-profile-user {
        background-color: $gray-600;
    }

    .noti-icon {
        i {
            color: $header-item-color;
        }
    }

    .notification-item {
        .d-flex {

            &:hover {
                background-color: $gray-100;
            }
        }
    }
}